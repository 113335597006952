import request from './request.js';
import public_request from './public_request';
//部门员工搜索
export function search({ keyWord }) {
    return request({
        url: '/personnel/search',
        method: 'GET',
        params: public_request({
            keyWord,
        }),
    });
}
//获取组织架构
export function departmentTrees() {
    return request({
        url: '/personnel/departments-tree',
        method: 'GET',
        params: public_request(),
    });
}

//获取部门详情
export function department_detail(data) {
    return request({
        url: '/personnel/department-detail',
        method: 'GET',
        params: public_request(data),
    });
}
//修改部门信息
export function modify_department({ departmentId, name }) {
    return request({
        url: '/personnel/department',
        method: 'PUT',
        data: public_request({
            departmentId,
            name,
        }),
    });
}
//添加部门
export function add_department({ parentDepartmentId, name, setPoolSea }) {
    return request({
        url: '/personnel/department',
        method: 'post',
        data: public_request({
            parentDepartmentId,
            name,
            setPoolSea,
        }),
    });
}
//删除部门
export function delete_department({ departmentId }) {
    return request({
        url: '/personnel/department',
        method: 'delete',
        data: public_request({
            departmentId,
        }),
    });
}
//添加员工
export function add_staff({ departmentId, roleId, name, username, mobileNumber, setting }) {
    return request({
        url: '/personnel/staff',
        method: 'post',
        data: public_request({
            departmentId,
            roleId,
            name,
            username,
            mobileNumber,
            setting,
        }),
    });
}
//获取员工信息
export function staffI({ staffId }) {
    return request({
        url: '/personnel/staff',
        method: 'get',
        params: public_request({
            staffId,
        }),
    });
}
//修改员工信息
export function modify_staff({ staffId, departmentId, roleId, name, username, mobileNumber, setting }) {
    return request({
        url: '/personnel/staff',
        method: 'put',
        data: public_request({
            staffId,
            departmentId,
            roleId,
            name,
            username,
            mobileNumber,
            setting,
        }),
    });
}
//删除员工
export function delete_staff({ staffId }) {
    return request({
        url: '/personnel/staff',
        method: 'DELETE',
        data: public_request({
            staffId,
        }),
    });
}
//启用禁用员工
export function open_staff({ staffId }) {
    return request({
        url: '/personnel/staff-enable',
        method: 'PUT',
        data: public_request({
            staffId,
        }),
    });
}
//获取员工客户列表
export function staff_clients(data) {
    return request({
        url: '/personnel/staff-clients',
        method: 'GET',
        params: public_request(data),
    });
}
//转移员工客户到公海
export function staff_clients_public(data) {
    return request({
        url: '/personnel/staff-clients-public',
        method: 'PUT',
        data: public_request(data),
    });
}
// 一键转移到公海
export function staff_clients_public_all(data) {
    return request({
        url: '/personnel/staff-clients-public-all',
        method: 'PUT',
        data: public_request(data),
    });
}
//转移员工客户
export function staff_clients_save({ outStaffId, inStaffId, clientIds }) {
    return request({
        url: '/personnel/staff-clients',
        method: 'PUT',
        data: public_request({
            outStaffId,
            inStaffId,
            clientIds,
        }),
    });
}

//全部转移员工客户
export function staff_clients_all(data) {
    return request({
        url: '/personnel/staff-clients-all',
        method: 'PUT',
        data: public_request(data),
    });
}

//批量修改员工部门
export function staffs_department({ staffIds, departmentId }) {
    return request({
        url: '/personnel/staffs-department',
        method: 'PUT',
        data: public_request({
            staffIds,
            departmentId,
        }),
    });
}
//重置员工密码
export function staff_password_reset({ staffId }) {
    return request({
        url: '/personnel/staff-password-reset',
        method: 'PUT',
        data: public_request({
            staffId,
        }),
    });
}
