<template>
    <div class="personnel">
        <div class="k">
            <!-- 树形控件 -->
            <div class="list">
                <div class="row">
                    <el-autocomplete
                        v-model="state"
                        style="padding:10px"
                        prefix-icon="el-icon-search"
                        class="inline-input"
                        :fetch-suggestions="querySearch"
                        placeholder="请输入内容"
                        :trigger-on-focus="false"
                        value-key="name"
                        @select="handleSelect"
                    />
                    <el-tree
                        ref="tree"
                        class="treec"
                        :data="data2"
                        highlight-current
                        :accordion="c"
                        node-key="id"
                        :props="defaultProps"
                        :default-expanded-keys="zhankai"
                        @node-click="rightClick"
                    >
                        <span slot-scope="{ node, data }" class="custom-tree-node">
                            <span style="font-size: 14px">
                                <i :class="data.icon" style="color: #4086EC;" />
                                {{ node.label }} ({{ data.staffCount }})
                            </span>
                        </span>
                    </el-tree>
                </div>
            </div>
            <!-- 右侧内容 -->
            <div class="content">
                <!-- 上面部门内容 -->
                <div v-if="!undivided" class="top">
                    <div class="row row1">
                        <div class="doc_top">
                            <!-- 面包屑导航 -->
                            <el-breadcrumb
                                separator-class="el-icon-arrow-right"
                                style="cursor: pointer"
                            >
                                <el-breadcrumb-item
                                    v-if="!breadOption"
                                    @click.native="handleToHome"
                                >{{ title }}</el-breadcrumb-item>

                                <el-breadcrumb-item
                                    v-for="item in breadOption"
                                    :key="item.id"
                                    @click.native="handleIn(item.id, item.data)"
                                >
                                    <font
                                        :style="{'font-weight': item.title == breadOption[breadOption.length - 1].title ? '600' : ''}"
                                    >{{ item.title }}</font>
                                </el-breadcrumb-item>
                            </el-breadcrumb>
                        </div>
                        <!-- 修改部门名称 -->
                        <h1 style="display:flex;align-items:center;justify-content:space-between;">
                            <div style="display:flex;align-items:center">
                                <i v-show="!isShow">{{ ok }}</i>
                                <el-input v-show="isShow" v-model="ok" />
                                <el-button
                                    v-show="isShow"
                                    type="primary"
                                    plain
                                    style="margin-left: 20px"
                                    size="mini"
                                    round
                                    @click="xiu"
                                >{{ zi }}</el-button>
                                <img
                                    v-show="!isShow"
                                    style="margin-left:10px;cursor: pointer;"
                                    width="20"
                                    height="20"
                                    src="@/assets/personnal/editName.svg"
                                    alt
                                    @click="xiu"
                                />
                            </div>
                            <el-button type="danger" size="mini" @click="remove(rowInfo)">删除部门</el-button>
                            <!-- <el-popconfirm title="确定要删除此部门吗？" @onConfirm="remove2">
                <el-button
                  style="margin-left: 15px"
                  type="danger"
                  slot="reference"
                  size="mini"
                  round
                  v-show="!none"
                  >删除部门</el-button
                >
                            </el-popconfirm>-->
                        </h1>
                        <!-- 添加下级 -->
                        <div class="adddepart-area">下级部门</div>
                        <!-- <p>
              <span class="next-depart">下级部门</span>
              <el-button type="primary" size="mini" @click="open"
                >添加下级</el-button
              >
                        </p>-->
                        <!-- 下级部门的渲染 -->
                        <div class="div">
                            <!-- <el-row :gutter="20" v-if="zbm.length>0">
                <el-col :span="2" v-for="(item, index) in zbm" :key="index">
                  <div class="depart-item">
                    {{ item.name }}
                    <i class="el-icon-close" @click="remove(item)"></i>
                  </div>
                </el-col>
                <el-col :span="3" >
                  <div class="depart-item add-depart-item" @click="open">
                    + 添加下级
                  </div>
                </el-col>
                            </el-row>-->
                            <span v-for="(item, index) in zbm" :key="index">
                                {{ item.name }}
                                <i class="el-icon-close" @click="remove(item)" />
                                <!-- <i @click="remove(item)">+</i> -->
                            </span>
                            <span v-if="zbm.length > 0" class="add" @click="open">
                                +添加下级
                                <!-- <i @click="remove(item)">+</i> -->
                            </span>
                            <p
                                v-show="zbm.length == 0"
                                style="margin-top: 0px; color: #565656; text-align: center"
                            >
                                当前部门不包含下级
                                <i
                                    style="width: auto;  cursor: pointer; text-decoration: underline;
                                 color: #458bee; "
                                    @click="open"
                                >添加下级部门</i>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 删除部门弹框 -->
                <el-dialog title="删除部门" :visible.sync="dialogVisible" width="364">
                    <p style="text-align: center;">
                        确定要删除这个部门吗
                        <i
                            style="font-style: normal;  color: #fcb12b; margin-top: 10px;
						  margin-bottom: 10px; display: inline-block; "
                        >{{ item }}</i>
                    </p>
                    <p
                        style="color: #999; font-size: 12px;text-align: center;"
                    >部门(包括下级)内员工将转移到【未划分部门】中</p>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="remove1()">确 定</el-button>
                        <el-button @click="dialogVisible = false">取 消</el-button>
                    </span>
                </el-dialog>
                <!-- 添加部门弹框 -->
                <el-dialog
                    title="添加部门"
                    class="add"
                    :visible.sync="dialogFormVisible"
                    width="500px"
                    center
                >
                    <el-form
                        ref="ruleFormTj"
                        :model="ruleFormTj"
                        :rules="rules"
                        label-width="150px"
                        class="demo-ruleForm"
                        label-position="right"
                    >
                        <el-form-item label="部门名称：" prop="name1">
                            <el-input v-model="ruleFormTj.name1" size="medium" />
                        </el-form-item>
                        <el-form-item label="上级部门：">
                            <span>{{ ok }}</span>
                        </el-form-item>
                        <el-form-item prop="setPublic">
                            <div
                                slot="label"
                                style="align-items: center;display: inline-flex;width:165px"
                            >
                                <div>设立公共池、公海</div>
                                <el-tooltip class="item" effect="dark" placement="right">
                                    <div slot="content">
                                        设立独立公共池、公海时，线索与客户超时先流入部
                                        <br />门池、部门海，再流入公司池、公司海
                                    </div>
                                    <img
                                        width="15"
                                        height="15"
                                        src="@/assets/personnal/tips.svg"
                                        alt
                                    />
                                </el-tooltip>：
                            </div>
                            <div
                                style="display: flex;align-items: center;justify-content: space-between;"
                            >
                                <div>
                                    <el-radio v-model="ruleFormTj.setPublic" :label="1">是</el-radio>
                                    <el-radio v-model="ruleFormTj.setPublic" :label="0">否</el-radio>
                                </div>
                            </div>
                        </el-form-item>
                        <div class="footer" style="text-align:center">
                            <el-button type="primary" @click="add('ruleFormTj')">确 定</el-button>
                            <el-button @click="dialogFormVisible = false">取 消</el-button>
                        </div>
                    </el-form>
                </el-dialog>
                <!-- 下面员工内容 -->
                <div
                    class="bottom"
                    :style="{ height: undivided ? '100%' : '80%' }"
                    :class="{ auto: undivided }"
                >
                    <div class="row">
                        <!-- 部门员工操作按钮 -->
                        <p class="p">
                            <span v-show="!undivided">部门员工</span>
                            <span v-show="undivided">未划分部门员工</span>
                            <el-button
                                size="mini"
                                :class="{ xuanze: qxanniu.length > 0 }"
                                :disabled="qxanniu.length <= 0"
                                plain
                                style="
                            margin-left:10px;color: #e3784b; border: 1px solid #e3784b;"
                                @click="dialogFormVisible2 = true"
                            >调整部门</el-button>
                            <el-button
                                size="mini"
                                type="primary"
                                style="margin: 0 15px"
                                @click="openadd"
                            >添加员工</el-button>
                        </p>
                        <!-- 调整部门的弹框 -->
                        <el-dialog
                            center
                            title="选择部门"
                            class="adjustment"
                            :visible.sync="dialogFormVisible2"
                            width="300px"
                        >
                            <div style="text-align:center">
                                <el-cascader
                                    ref="cascaderHandle1"
                                    v-model="adjustment"
                                    :options="options"
                                    :props="{ expandTrigger: 'hover',
                            checkStrictly: true, label: 'name', value: 'id' }"
                                    placeholder="选择部门"
                                    clearable
                                    @change="adjustmentSave"
                                />
                            </div>

                            <div slot="footer" class="dialog-footer">
                                <el-button type="primary" @click="Adjustment">确 定</el-button>
                                <el-button @click="dialogFormVisible2 = false">取 消</el-button>
                            </div>
                        </el-dialog>

                        <!-- 添加员工的弹框 -->
                        <el-dialog
                            width="40%"
                            :title="exchange ? '添加员工' : '编辑员工'"
                            class="tk"
                            :visible.sync="dialogFormVisible1"
                        >
                            <div>
                                <!-- 基本信息 -->
                                <div class="flex-sb base-info">
                                    <div>基本信息</div>
                                    <div v-if="exchange" class="flex-sb">
                                        <img
                                            width="20"
                                            height="20"
                                            src="@/assets/setting/tipIcon.png"
                                            alt
                                        />
                                        <div>用户名创建后不可修改</div>
                                    </div>
                                    <div v-else>
                                        <el-button
                                            v-if="!exchange"
                                            class="reset-password"
                                            style="margin-left: 15px"
                                            type="primary"
                                            size="mini"
                                            @click="reset"
                                        >重置密码</el-button>
                                    </div>
                                </div>
                                <!-- 表单区域 -->
                                <el-form
                                    ref="ruleForm"
                                    :model="ruleForm"
                                    :rules="rules"
                                    class="demo-form-inline"
                                    label-position="right"
                                >
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item label-width="70px" label="姓名" prop="name">
                                                <el-input
                                                    v-model="ruleForm.name"
                                                    size="medium"
                                                    placeholder="请输入姓名"
                                                />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item
                                                label-width="70px"
                                                label="用户名"
                                                prop="username"
                                            >
                                                <el-input
                                                    v-if="exchange"
                                                    v-model="ruleForm.username"
                                                    size="medium"
                                                    placeholder="请输入用户名"
                                                />
                                                <div v-else>{{ ruleForm.username }}</div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item
                                                label-width="70px"
                                                label="手机号"
                                                prop="mobileNumber"
                                            >
                                                <el-input
                                                    v-model="ruleForm.mobileNumber"
                                                    size="medium"
                                                    oninput="value=value.replace(/[^\d]/g,'')"
                                                    placeholder="请输入手机号"
                                                />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item
                                                label-width="70px"
                                                label="角色"
                                                prop="roleId"
                                            >
                                                <el-select
                                                    v-model="ruleForm.roleId"
                                                    style="width:100%"
                                                    size="medium"
                                                    placeholder="请选择角色"
                                                >
                                                    <el-option
                                                        v-for="item in characterList"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label-width="70px" label="微信号">
                                                <el-input
                                                    v-model="ruleForm.setting.wechat"
                                                    size="medium"
                                                    placeholder="请输入微信号"
                                                />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <!-- <el-row style="margin: 10px 0 20px 0;">
										<div style="display: flex;flex-direction: row;align-items: flex-start;">
											<el-col :span="12">
												<div><span style="color: #ff0000">*</span>是否可以呼叫</div>
											</el-col>
											<el-col :span="6">
												<el-radio v-model="radio" label="1">是</el-radio>
											</el-col>
											<el-col :span="6">
												<el-radio v-model="radio" label="2">否</el-radio>
											</el-col>
											<el-col :span="12">
												<div style="color: #ff0000;">未开通呼叫服务，请先开通</div>
											</el-col>
										</div>
                                    </el-row>-->
                                    <div class="call-choose">
                                        <div class="flex-sb" style="margin-bottom: 3px;">
                                            <div style="color: #ff0000">*</div>
                                            <div>是否禁用呼叫</div>
                                        </div>
                                        <el-radio-group v-model="radio" @change="getRadioChange">
                                            <el-radio label="0">是</el-radio>
                                            <el-radio label="1" style="margin-left: 30px;">否</el-radio>
                                        </el-radio-group>
                                        <div
                                            v-if="kaiTon==0"
                                            style="color: #ff0000;margin-bottom: 3px;"
                                        >未开通呼叫服务，请先开通</div>
                                    </div>

                                    <!-- 线索接受设置 -->
                                    <div class="flex-sb base-info">
                                        <div>接收线索设置</div>
                                        <div class="flex-sb">
                                            <div style="margin-right:5px">接收开关</div>
                                            <el-switch
                                                v-model="ruleForm.setting.clueSwitch1"
                                                @change="switchChange"
                                            />
                                        </div>
                                    </div>

                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item
                                                class="receive"
                                                label-width="140px"
                                                label="日接收数量上限："
                                                :prop="ert.dailyReceiveMax"
                                            >
                                                <div
                                                    class="flex"
                                                    style="align-items:center;height: 40px;line-height: 40px;"
                                                >
                                                    <div class="flex">
                                                        <el-radio
                                                            v-model="dataRandom"
                                                            :disabled="!ruleForm.setting.clueSwitch1"
                                                            :label="0"
                                                        >不限</el-radio>
                                                        <el-radio
                                                            v-model="dataRandom"
                                                            :disabled="!ruleForm.setting.clueSwitch1"
                                                            :label="1"
                                                        >自定义</el-radio>
                                                    </div>

                                                    <div
                                                        v-if="dataRandom == 1"
                                                        class="flex"
                                                        style="align-items:center;margin-left:10px"
                                                    >
                                                        <el-input
                                                            v-model="ruleForm.setting.dailyReceiveMax"
                                                            style="margin-right:5px"
                                                            size="medium"
                                                            :disabled="!ruleForm.setting.clueSwitch1"
                                                            oninput="value=value.replace(/[^\d]/g,'')"
                                                            placeholder="请输入内容"
                                                        />条
                                                    </div>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24">
                                            <el-form-item
                                                class="receive"
                                                label-width="140px"
                                                label="资金需求上限："
                                                :prop="ert.clueFundNeedsMax"
                                                :disabled="ruleForm.setting.clueSwitch1"
                                            >
                                                <!-- <el-col :span="24"> -->
                                                <!-- <div class="flex-sb flex"> -->
                                                <div class="flex" style="align-items:center">
                                                    <div
                                                        class="flex"
                                                        style="align-items:center;height: 40px;line-height: 40px;"
                                                    >
                                                        <el-radio
                                                            v-model="moneyRandom"
                                                            :disabled="!ruleForm.setting.clueSwitch1"
                                                            :label="0"
                                                        >不限</el-radio>
                                                        <el-radio
                                                            v-model="moneyRandom"
                                                            :disabled="!ruleForm.setting.clueSwitch1"
                                                            :label="1"
                                                        >自定义</el-radio>
                                                    </div>
                                                    <div
                                                        v-if="moneyRandom == 1"
                                                        class="flex"
                                                        style="margin-left:10px"
                                                    >
                                                        <el-input
                                                            v-model="ruleForm.setting.clueFundNeedsMax"
                                                            size="medium"
                                                            style="margin-right:5px"
                                                            :disabled="!ruleForm.setting.clueSwitch1"
                                                            oninput="value=value.replace(/[^\d]/g,'')"
                                                            placeholder="请输入内容"
                                                        />万
                                                    </div>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24">
                                            <el-form-item label="可接收资质：" label-width="140px">
                                                <el-checkbox-group
                                                    v-model="ruleForm.setting.clueQualityType1"
                                                >
                                                    <el-checkbox
                                                        v-for="(item, index) in quality"
                                                        :key="index"
                                                        :label="item.number"
                                                        :disabled="!ruleForm.setting.clueSwitch1"
                                                        name="clueQualityType1"
                                                    >{{ item.cnVal }}</el-checkbox>
                                                </el-checkbox-group>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                                <!-- 接收线索 -->

                                <!-- 表单 -->
                            </div>
                            <div style="text-align:center">
                                <el-button
                                    v-if="exchange"
                                    size="small"
                                    type="primary"
                                    @click="submitForm('ruleForm')"
                                >保存</el-button>
                                <el-button
                                    v-if="!exchange"
                                    size="small"
                                    type="primary"
                                    @click="editForm('ruleForm')"
                                >保存</el-button>
                                <el-button size="small" @click="resetForm('ruleForm')">取消</el-button>
                            </div>

                            <!-- <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="120px"
                class="demo-ruleForm"
              >
                <el-row :gutter="20">
                  <el-col :span="10" class="jb">
                    <p>
                      基本信息<el-button
                        style="margin-left: 15px"
                        v-if="!exchange"
                        type="primary"
                        size="mini"
                        @click="reset"
                        >重置密码</el-button
                      >
                    </p>
                    <div class="grid-content bg-purple">
                      <el-form-item label="姓名" prop="name">
                        <el-input
                          v-model="ruleForm.name"
                          placeholder="请输入姓名"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="手机号" prop="mobileNumber">
                        <el-input
                          v-model="ruleForm.mobileNumber"
                          oninput="value=value.replace(/[^\d]/g,'')"
                          placeholder="请输入手机号"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="用户名" prop="username">
                        <el-input
                          v-model="ruleForm.username"
                          placeholder="请输入用户名"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="角色" prop="roleId">
                        <el-select
                          v-model="ruleForm.roleId"
                          placeholder="请选择角色"
                        >
                          <el-option
                            v-for="item in characterList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="14">
                    <p class="kg">
                      <el-form-item label="线索接收设置">
                        <el-switch
                          v-model="ruleForm.setting.clueSwitch1"
                          @change="switchChange"
                        ></el-switch>
                      </el-form-item>
                    </p>
                    <div class="grid-content bg-purple">
                      <el-form-item
                        label="日接收数量上限"
                        :prop="ert.dailyReceiveMax"
                      >
                        <el-input
                          :disabled="!ruleForm.setting.clueSwitch1"
                          v-model="ruleForm.setting.dailyReceiveMax"
                          oninput="value=value.replace(/[^\d]/g,'')"
                          placeholder="请输入内容"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="资金需求上限"
                        :prop="ert.clueFundNeedsMax"
                        :disabled="ruleForm.setting.clueSwitch1"
                      >
                        <el-input
                          :disabled="!ruleForm.setting.clueSwitch1"
                          v-model="ruleForm.setting.clueFundNeedsMax"
                          oninput="value=value.replace(/[^\d]/g,'')"
                          placeholder="请输入内容"
                        >
                          <template slot="append">万</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="资质范围">
                        <el-checkbox-group
                          v-model="ruleForm.setting.clueQualityType1"
                        >
                          <el-checkbox
                            v-for="(item, index) in quality"
                            :key="index"
                            :label="item.number"
                            :disabled="!ruleForm.setting.clueSwitch1"
                            name="clueQualityType1"
                            >{{ item.cnVal }}</el-checkbox
                          >
                        </el-checkbox-group>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
                <el-form-item class="button">
                  <el-button
                    type="primary"
                    @click="submitForm('ruleForm')"
                    v-if="exchange"
                    >立即创建</el-button
                  >
                  <el-button
                    type="primary"
                    @click="editForm('ruleForm')"
                    v-if="!exchange"
                    >保存</el-button
                  >
                  <el-button @click="resetForm('ruleForm')">取消</el-button>
                </el-form-item>
                            </el-form>-->
                        </el-dialog>

                        <!-- 部门员工的表格渲染 -->
                        <el-table
                            v-show="tableData.length > 0"
                            :data="tableData"
                            style="width: 100%"
                            border
                            :height="tableHeight"
                            @selection-change="handleSelectionChange"
                        >
                            <el-table-column type="selection" width="55" />
                            <el-table-column label="序号" type="index" width="50" />
                            <el-table-column prop="name" label="姓名" />
                            <el-table-column prop="mobileNumber" label="手机号" />
                            <el-table-column prop="username" label="用户名" />
                            <el-table-column prop="role" label="角色" />
                            <el-table-column label="是否启用">
                                <template slot-scope="scope">
                                    <el-switch
                                        v-model="scope.row.enable"
                                        :active-value="1"
                                        :inactive-value="0"
                                        @change="openstatus(scope.row)"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="创建时间" width="160" />
                            <el-table-column fixed="right" label="操作" width="120">
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        style="margin-right: 10px"
                                        size="small"
                                        @click="handleClick(scope.row)"
                                    >编辑</el-button>
                                    <el-popover
                                        :ref="`popover-${scope.$index}`"
                                        placement="top-start"
                                        width="330"
                                    >
                                        <div v-if="!haveClient" class="box">
                                            <p style="margin-bottom: 7px">
                                                确定删除此员工
                                                <span
                                                    style="color: #fcb22c"
                                                >{{ scope.row.name }}</span>？
                                            </p>
                                            <div style="text-align: right; margin: 0">
                                                <el-button
                                                    size="mini"
                                                    @click="scope._self.$refs[
                            `popover-${scope.$index}`].doClose()"
                                                >取消</el-button>
                                                <span @click="ygremove(scope.row)">
                                                    <el-button
                                                        type="primary"
                                                        size="mini"
                                                        @click="
                              scope._self.$refs[`popover-${scope.$index}`].doClose()"
                                                    >确定</el-button>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-else class="box">
                                            <p style="margin-bottom: 30px">员工名下还有未转移的客户，请先转移客户！</p>
                                            <div style="text-align: right; margin: 0">
                                                <el-button
                                                    size="mini"
                                                    @click=" scope._self.$refs[
                            `popover-${scope.$index}`].doClose()"
                                                >取消</el-button>
                                                <span @click="transfer(scope.row)">
                                                    <el-button
                                                        type="primary"
                                                        size="mini"
                                                        @click="scope._self.$refs[
                              `popover-${scope.$index}`].doClose()"
                                                    >转移客户</el-button>
                                                </span>
                                            </div>
                                        </div>
                                        <el-link
                                            slot="reference"
                                            :underline="false"
                                            type="primary"
                                            style="color:#DE3C3C"
                                            @click="deleteBtn(scope.row)"
                                        >删除</el-link>
                                    </el-popover>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div
                            v-show="tableData.length > 0"
                            class="block"
                            style="margin-top: 20px; text-align: right"
                        >
                            <el-pagination
                                :current-page="pageNo"
                                :page-size="pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="tableDataLen"
                                @size-change="handleSizeTableChange"
                                @current-change="handleCurrentTableChange"
                            />
                        </div>
                        <div v-show="tableData.length == 0" class="none">
                            <div class="item">
                                <img src="../../../public/images/ygnone.png" alt />
                                <p>暂无员工哦，快去添加员工吧！</p>
                            </div>
                        </div>
                        <!-- 重分配弹框 -->
                        <el-dialog title="重分配客户" :visible.sync="cfp" class="cfp">
                            <div class="searchOpen">
                                <search
                                    style="margin-top:10px"
                                    :label-arr="labelArr"
                                    :is-customer="true"
                                    :show-open-close="true"
                                    @clickData="clickData"
                                />
                                <!-- 搜索区域 -->
                                <div class="search flex-sb">
                                    <div class="top-search">
                                        <el-button
                                            :disabled="clientsIds.length == 0"
                                            size="mini"
                                            type="warning"
                                            @click="regive(0)"
                                        >重分配</el-button>
                                        <el-button
                                            size="mini"
                                            type="danger"
                                            style="margin-right:10px"
                                            :disabled="clientsIds.length == 0"
                                            @click="throwSea(0)"
                                        >丢公海</el-button>
                                        <el-dropdown
                                            style="margin-right:10px;width:90px;"
                                            @command="handleCommand"
                                        >
                                            <el-button size="mini" type="primary">
                                                一键分配
                                                <i class="el-icon-arrow-down el-icon--right" />
                                            </el-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item command="a">全部重分配</el-dropdown-item>
                                                <el-dropdown-item command="b">全部丢公海</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                        <el-input
                                            v-model="clientStatus.keyword"
                                            style="width:200px"
                                            clearable
                                            size="medium"
                                            placeholder="请输入姓名/手机号/ID"
                                            @keypress.native.enter="findData"
                                            @clear="findData"
                                        />
                                        <!-- <i class="el-icon-search"></i> -->
                                        <div class="icon-area" @click="findData">
                                            <img
                                                width="20"
                                                height="20"
                                                src="@/assets/common/search.png"
                                                alt
                                            />
                                        </div>
                                    </div>
                                    <div class="flex-sb right-time">
                                        <div>最后跟进时间</div>
                                        <div>
                                            <el-radio-group
                                                v-model="timeTabs"
                                                size="small"
                                                @change="changeTime"
                                            >
                                                <el-radio-button label="0">全部</el-radio-button>
                                                <el-radio-button label="1">今日</el-radio-button>
                                                <el-radio-button label="2">昨日</el-radio-button>
                                                <el-radio-button label="3">最近一周</el-radio-button>
                                            </el-radio-group>
                                        </div>
                                        <div>
                                            <el-date-picker
                                                v-model="valuetime"
                                                style="width: 350px;"
                                                size="small"
                                                type="datetimerange"
                                                range-separator="-"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                value-format="timestamp"
                                                :default-time="['00:00:00', '23:59:59']"
                                                @change="time"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-top:10px">
                                    <el-table
                                        ref="multipleTable"
                                        :data="tableData1"
                                        style="width: 100%;border: 1px solid #DCDFE6;"
                                        border
                                        height="265"
                                        @selection-change="handleSelectionChange1"
                                    >
                                        <el-table-column type="selection" width="55" />
                                        <el-table-column label="序号" type="index" width="50" />
                                        <el-table-column label="重要" width="55">
                                            <template slot-scope="scope">
                                                <i
                                                    class="iconfont icon-aixin xin"
                                                    :class="{ ping: scope.row.important == 1 }"
                                                    @click="zhongyao(scope.row)"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="name" label="客户姓名" width="100" />
                                        <!-- <el-table-column label="跟进人" >
                      <template>
                        <span>{{ renName }}</span>
                      </template>
                                        </el-table-column>-->
                                        <el-table-column label="客户资质" width="190">
                                            <template slot-scope="scope">
                                                <span
                                                    v-if="scope.row.hasHouse == 1"
                                                    class="icon house"
                                                    style="margin-right: 10px"
                                                >房</span>
                                                <span
                                                    v-if="scope.row.hasVehicle == 1"
                                                    class="icon car"
                                                    style="margin-right: 10px"
                                                >车</span>
                                                <span
                                                    v-if="scope.row.hasShebao == 1"
                                                    style="margin-right: 10px"
                                                    class="icon shebao"
                                                >社</span>
                                                <span
                                                    v-if="scope.row.hasGongjijin == 1"
                                                    class="icon gongjijin"
                                                    style="margin-right: 10px"
                                                >金</span>
                                                <span
                                                    v-if="scope.row.hasBaodan == 1"
                                                    style="margin-right: 10px"
                                                    class="icon baodan"
                                                >保</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="资金需求" width="150">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.fundNeeds }}万</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            width="100"
                                            prop="followStage"
                                            label="跟进阶段"
                                        />
                                        <el-table-column
                                            width="100"
                                            prop="purposeLevel"
                                            label="意向度"
                                        />
                                        <el-table-column
                                            prop="qualityLevel"
                                            label="资质星级"
                                            width="150"
                                        >
                                            <template slot-scope="scope">
                                                <el-rate
                                                    v-model="scope.row.qualityLevel"
                                                    disabled
                                                    text-color="#ff9900"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="lastFollowedTime"
                                            label="最近跟进时间"
                                            width="200"
                                        />
                                    </el-table>
                                </div>
                            </div>
                            <div slot="footer" class="block">
                                <el-pagination
                                    :current-page="page"
                                    :page-size="size"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="len"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                />
                            </div>
                            <assign-dialog
                                :show-dialog.sync="showDialog"
                                :title="asstitle"
                                @submitPartment="submitPartment"
                            />
                            <el-dialog
                                width="22%"
                                title="请选择要丢弃的公海"
                                :visible.sync="showThrowArr"
                                append-to-body
                            >
                                <el-select
                                    v-model="departSeaVal"
                                    clearable
                                    size="small"
                                    style="width:100%"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in departmentSea"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    />
                                </el-select>
                                <div slot="footer" class="dialog-footer">
                                    <el-button
                                        type="primary"
                                        size="medium"
                                        @click="submitThrowSea"
                                    >确 定</el-button>
                                    <el-button size="medium" @click="closeThrowSea">取 消</el-button>
                                </div>
                            </el-dialog>
                        </el-dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    departmentTrees,
    department_detail,
    modify_department,
    add_department,
    delete_department,
    search,
    add_staff,
    staffI,
    modify_staff,
    delete_staff,
    open_staff,
    staff_clients,
    staff_clients_public,
    staff_clients_public_all,
    staff_clients_save,
    staff_clients_all,
    staffs_department,
    staff_password_reset,
} from '../../api/personnel.js';
import { departments, staff, companySea } from '../../api/public_content.js';
import { roles } from '../../api/character.js';
import { getYMDHMS } from '../../assets/js/time.js';
// import { c_values } from "../../api/public_content";
// import labelList from "../public_components/label_selection.vue";
// import { forEach } from "element-resize-detector/src/collection-utils";
export default {
    data() {
        let dailyReceiveMaxRules = (rule, value, callback) => {
            if (this.dataRandom == 1) {
                if (value === '') {
                    callback();
                } else {
                    if (value < 0 || value > 127) {
                        callback(new Error('有效值范围是0-127'));
                    } else {
                        callback();
                    }
                }
            } else {
                callback();
            }
            // if (value === '') {
            //
            // } else {
            //   if (this.ruleForm.checkPass !== '') {
            //     this.$refs.ruleForm.validateField('checkPass');
            //   }
            //   callback();
            // }
        };
        let clueFundNeedsMaxRules = (rule, value, callback) => {
            if (this.moneyRandom == 1) {
                console.log(rule, value, callback);
                if (value === '') {
                    callback(new Error('请输入内容'));
                } else {
                    console.log(value);
                    if (value < 0 || value > 10000) {
                        callback(new Error('有效值范围是0-10000'));
                    } else {
                        callback();
                    }
                }
            } else {
                callback();
            }
        };
        return {
            kaiTon: null,
            radio: '0',
            adjustment: '',
            options: [],
            tableData: [],
            breadOption: '',
            ind: '',
            ok: '',
            zbm: '',
            c: true,
            isShow: false,
            zi: '修改',
            dialogFormVisible: false,
            input: '',
            state: '',
            data2: [],
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            dialogFormVisible1: false,
            qxanniu: [],
            dialogVisible: false,
            item: '',
            tkong: '',
            restaurants: '',
            zhankai: [1, 2],
            ruleForm: {
                departmentId: '',
                roleId: '',
                name: '',
                username: '',
                mobileNumber: '',
                setting: {
                    clueSwitch1: true,
                    dailyReceiveMax: '',
                    clueFundNeedsMax: '',
                    clueQualityType1: [],
                    callServiceEnabled: '0',
                    wechat: '',
                },
            },
            ert: {
                clueQualityType1: 'setting.clueQualityType1',
                dailyReceiveMax: 'setting.dailyReceiveMax',
                clueFundNeedsMax: 'setting.clueFundNeedsMax',
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur',
                    },
                    {
                        min: 1,
                        max: 32,
                        message: '长度在 1到 32 个字符',
                        trigger: 'blur',
                    },
                    {
                        validator: this.formValidation.name,
                        trigger: 'blur',
                    },
                ],
                name1: [
                    {
                        required: true,
                        message: '请输入部门名称',
                        trigger: 'blur',
                    },
                    {
                        min: 1,
                        max: 32,
                        message: '长度在 1到 32 个字符',
                        trigger: 'blur',
                    },
                    {
                        validator: this.formValidation.heading,
                        trigger: 'blur',
                    },
                ],
                mobileNumber: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur',
                    },
                    {
                        validator: this.formValidation.mobile,
                        trigger: 'blur',
                    },
                ],
                username: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur',
                    },
                    {
                        min: 6,
                        max: 20,
                        message: '长度在 6到 20 个字符',
                        trigger: 'blur',
                    },
                    {
                        validator: this.formValidation.username,
                        trigger: 'blur',
                    },
                ],
                roleId: [
                    {
                        required: true,
                        message: '请选择角色',
                        trigger: 'change',
                    },
                ],
                setting: {
                    dailyReceiveMax: [
                        {
                            validator: dailyReceiveMaxRules,
                            trigger: 'change',
                            required: true,
                        },
                        // { required: true, message: "请输入内容", trigger: "blur" },
                        // { validator: this.formValidation.ldq, trigger: "blur" },
                    ],
                    clueFundNeedsMax: [
                        {
                            validator: clueFundNeedsMaxRules,
                            trigger: 'change',
                            required: true,
                        },
                        // { required: true, message: "请输入内容", trigger: "blur" },
                        // { validator: this.formValidation.ldyw, trigger: "blur" },
                    ],
                },
            },
            ruleFormTj: {
                name1: '',
                setPublic: 0,
            },
            cfp: false,
            tableData1: [],
            dialogTableVisible: false,
            title: '',
            treeopen: true,
            characterList: [],
            exchange: true,
            haveClient: false,
            len: 1,
            size: 10,
            page: 0,
            outStaffId: '',
            dialogFormVisible2: false,
            options1: [],
            cc: '',
            ccc: [],
            // labelList: ["客户资质", "资金需求", "客户状态"],
            quality: [],
            renName: '',
            clientsIds: [],
            clientStatus: {
                keyword: '',
                staffId: '',
                qualityType: '',
                fundNeedsScopeType: '',
                followStage: '',
                getCategory: '',
                orderBy: '',
                qualityLevel: '',
                purposeLevel: '',
                startTime: '',
                endTime: '',
                pageNo: 1,
                pageSize: 10,
            },
            rowInfo: {},
            dataRandom: 0, //日接收数量上限：
            moneyRandom: 0, //资金需求上限：
            obj: {}, // 搜索-存放找到的路由id整条数据
            pageNo: 1,
            pageSize: 10,
            tableDataLen: 0,
            tableHeight: window.innerHeight - 310,
            labelArr: [
                '客户资质',
                '资金需求',
                '跟进阶段',
                '意向度',
                '资质星级',
            ],
            valuetime: '',
            timeTabs: 0,
            personTableData: [],
            getNodeItem: {},
            showDialog: false,
            showThrowArr: false,
            asstitle: '选择要重分配的对象',
            departmentSea: [], //公司池公司海
            departSeaVal: '', //公司池公司海的值
            outSeaType: '',
            callUseChange: '0', //获取打电话的val
        };
    },
    watch: {
        ind(val) {
            this.getHeight();
        },
    },
    destroyed() {
        window.removeEventListener('resize', this.getHeight);
    },
    mounted() {
        console.log(this.radio, 'ds');
        console.log(this.ruleForm.mobileNumber, 'd');

        window.addEventListener('resize', this.getHeight);
        //页面初始化渲染
        departmentTrees().then((res) => {
            if (res.code == 0) {
                this.data2 = res.data.list;
                // 做放入icon处理
                this.data2.forEach((i) => {
                    this.forIcon(i, 1);
                });
                // 路由中带有部门id或者员工名称的判断
                let routeId = this.$route.params.id
                    ? this.$route.params.id
                    : '';
                let routeName = this.$route.params.name
                    ? this.$route.params.name
                    : '';
                if (routeId) {
                    let obj = this.findTreeId(this.data2, routeId, '');
                    this.xr(obj);
                } else {
                    if (routeName) {
                        console.log(routeName);
                        console.log(this.data2);
                        let changeRouteName =
                            routeName == '未划分'
                                ? '未划分部门员工'
                                : routeName;
                        let objByname = this.findTreeName(
                            this.data2,
                            changeRouteName
                        );
                        console.log(objByname);
                        this.xr(objByname);
                    } else {
                        if (this.data2.length > 0) {
                            this.title = this.data2[0].name;
                            this.xr(this.data2[0]);
                        }
                    }
                }
            } else {
                this.$message.error(res.message);
            }
        });
        //获取角色列表数据
        roles().then((res) => {
            if (res.code == 0) {
                this.characterList = res.data.list;
            } else {
                this.$message.error(res.error);
            }
        });

        let res = this.common.allValueNeed('loan-quality-type');
        this.quality = res;
    },
    components: {
        // labelList,
        search: () => import('@/components/templates/search-open'),
        assignDialog: () => import('../client/components/assignDialog'),
    },
    computed: {
        none() {
            for (let i = 0; i < this.data2.length; i++) {
                if (this.data2[i].id === this.ind.id) {
                    return true;
                }
            }
            return false;
        },
        undivided() {
            return this.ind.name === '未划分部门员工' ? true : false;
        },
    },
    methods: {
        // 获取是否禁止打电话按钮的val
        getRadioChange(val) {
            console.log(val, 'ad');
            this.callUseChange = val;
            this.ruleForm.setting.callServiceEnabled = val + '';
        },

        getHeight() {
            this.tableHeight =
                this.ind.name === '未划分部门员工'
                    ? window.innerHeight - 280
                    : window.innerHeight - 480;
        },
        // 图标递归
        forIcon(item, level) {
            if (level) {
                item.icon = 'iconfont icon-fenzu';
            } else {
                item.icon = 'iconfont icon-bumen';
            }

            if (item.children.length > 0) {
                item.children.forEach((i) => {
                    this.forIcon(i);
                });
            } else {
                return;
            }
        },
        // 路由传值找到item
        findTreeId(data, id) {
            data.forEach((item) => {
                if (id == item.id) {
                    this.obj = item;
                } else {
                    if (item.children.length > 0) {
                        this.findTreeId(item.children, id);
                    }
                }
            });
            return this.obj;
        },
        // 路由传值找到item
        findTreeName(data, name) {
            // console.log(name);
            data.forEach((item) => {
                if (item.name == name) {
                    this.obj = item;
                    console.log(item);
                } else {
                    if (item.children.length > 0) {
                        this.findTreeName(item.children, name);
                    }
                }
            });
            return this.obj;
        },
        findTreeStaffItem(data, departmentId) {
            data.forEach((item) => {
                if (item.id == departmentId) {
                    this.obj = item;
                    console.log(item);
                } else {
                    if (item.children.length > 0) {
                        this.findTreeStaffItem(item.children, departmentId);
                    }
                }
            });
            return this.obj;
        },
        //判断是否是必填项
        switchChange(val) {
            if (val === false) {
                this.ert = {
                    dailyReceiveMax: '',
                    clueFundNeedsMax: '',
                };
            } else {
                this.ert = {
                    dailyReceiveMax: 'setting.dailyReceiveMax',
                    clueFundNeedsMax: 'setting.clueFundNeedsMax',
                };
            }
        },
        //点击删除按钮判断此员工名下是否有客户，没有展示删除按钮，如有展示转移客户按钮
        deleteBtn(data) {
            this.renName = data.name;
            staff_clients({
                staffId: data.id,
            }).then((res) => {
                if (res.code == 0) {
                    if (res.data.list.length > 0) {
                        //有客户的情况下
                        this.$nextTick(() => {
                            this.haveClient = true;
                        });
                    } else {
                        //没有客户的情况下
                        this.haveClient = false;
                    }
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        xrclients(arr) {
            staff_clients(arr).then((res) => {
                if (res.code == 0) {
                    this.tableData1 = res.data.list;
                    this.len = res.data.total;
                    console.log(res);
                    for (let i = 0; i < this.tableData1.length; i++) {
                        this.tableData1[i].lastFollowedTime = getYMDHMS(
                            this.tableData1[i].lastFollowedTime
                        );
                        let purposeLevel =
                            this.common.allValueNeed('loan-purpose-level');
                        purposeLevel.forEach((x) => {
                            if (this.tableData1[i].purposeLevel == x.number) {
                                this.tableData1[i].purposeLevel = x.cnVal;
                            }
                        });
                        // c_values({ dictionaryCode: "client-follow-stage" }).then((res) => {
                        //   let c = res.data.list.find((item) => {
                        //     return this.tableData1[i].followStage == item.number;
                        //   });
                        //   this.tableData1[i].followStage = c.cnVal;
                        // });

                        let res = this.common.allValueNeed(
                            'client-follow-stage'
                        );
                        let c = res.find((item) => {
                            return (
                                this.tableData1[i].followStage == item.number
                            );
                        });
                        this.tableData1[i].followStage = c.cnVal;
                    }
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //显示转移客户的列表
        transfer(data) {
            console.log(data);
            this.cfp = true;
            this.outStaffId = data.id;
            this.clientStatus.staffId = data.id;
            this.clientStatus.pageNo = this.page;
            this.clientStatus.pageSize = this.size;
            this.xrclients(this.clientStatus);
            if (this.$refs.child) {
                this.$refs.child.parentMsg(null);
            }
        },
        //翻页
        handleSizeChange(val) {
            this.size = val;
            this.clientStatus.pageSize = this.size;
            this.xrclients(this.clientStatus);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.clientStatus.pageNo = this.page;
            this.xrclients(this.clientStatus);
        },
        handleSizeTableChange(val) {
            this.pageSize = val;
            this.xr(this.ind);
        },
        handleCurrentTableChange(val) {
            this.pageNo = val;
            this.xr(this.ind);
        },
        //重置密码
        reset() {
            staff_password_reset({
                staffId: this.ruleForm.staffId,
            }).then((res) => {
                if (res.code == '0') {
                    this.$message({
                        message: '重置成功，新密码已发送到您的手机中',
                        type: 'success',
                    });
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        adjustmentSave() {
            this.$refs.cascaderHandle1.dropDownVisible = false;
        },
        //转移员工
        Adjustment() {
            staffs_department({
                staffIds: this.qxanniu,
                departmentId: this.adjustment[this.adjustment.length - 1],
            }).then((res) => {
                if (res.code == '0') {
                    this.$message({
                        message: '已成功将此员工转移部门',
                        type: 'success',
                    });
                    this.dialogFormVisible2 = false;
                    this.xr(this.ind);
                    departmentTrees().then((res) => {
                        this.data2 = res.data.list;
                    });
                } else {
                    this.$message.error('转移部门失败');
                }
            });
        },
        //选择部门
        casc(val) {
            this.$refs.cascaderHandle.dropDownVisible = false;
            staff({
                departmentId: val[val.length - 1],
            }).then((res) => {
                if (res.code == 0) {
                    this.options1 = res.data.list;
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //选择员工
        handleSelectionChange1(val) {
            this.clientsIds = [];
            for (let i = 0; i < val.length; i++) {
                this.clientsIds.push(val[i].id);
            }
        },
        //确定分配按钮
        // fenpei() {
        //   staff_clients_save({
        //     outStaffId: this.outStaffId,
        //     inStaffId: this.cc,
        //     clientIds: this.clientsIds,
        //   }).then((res) => {
        //     if (res.code == 0) {
        //       this.$message({
        //         message: "已成功分配到此销售",
        //         type: "success",
        //       });
        //       this.xrclients(this.clientStatus);
        //       this.dialogTableVisible = false;
        //     } else {
        //       this.$message.error(res.error);
        //     }
        //   });
        // },
        //添加员工弹框
        openadd() {
            this.dialogFormVisible1 = true;
            this.exchange = true;
            this.ruleForm = {
                departmentId: '',
                roleId: '',
                name: '',
                username: '',
                mobileNumber: '',
                setting: {
                    clueSwitch1: true,
                    dailyReceiveMax: '',
                    clueFundNeedsMax: '',
                    clueQualityType1: [],
                    wechat: '',
                },
            };
        },
        //添加员工操作
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.departmentId = this.ind.id;
                    let text = this.ruleForm.setting.clueQualityType1;
                    let c = text.join(',');
                    this.ruleForm.setting.clueQualityType = c;
                    this.ruleForm.setting.clueSwitch =
                        this.ruleForm.setting.clueSwitch1 === true ? 1 : 0;
                    console.log(this.ruleForm);
                    // 这一步为了修改不必要的字段
                    // -------------------
                    let _thisFm = this.ruleForm;
                    let submitData = {
                        departmentId: _thisFm.departmentId,
                        roleId: _thisFm.roleId,
                        name: _thisFm.name,
                        username: _thisFm.username,
                        mobileNumber: _thisFm.mobileNumber,
                        WxNumber: _thisFm.WxNumber,
                        setting: {
                            clueSwitch: _thisFm.setting.clueSwitch,
                            dailyReceiveMax:
                                this.dataRandom == 0
                                    ? 0
                                    : _thisFm.setting.dailyReceiveMax,
                            clueFundNeedsMax:
                                this.moneyRandom == 0
                                    ? 0
                                    : _thisFm.setting.clueFundNeedsMax,
                            clueQualityType: _thisFm.setting.clueQualityType,
                            callServiceEnabled: this.callUseChange,
                            wechat: _thisFm.setting.wechat,
                        },
                    };
                    console.log(submitData);
                    // --------------------
                    add_staff(submitData)
                        .then((res) => {
                            if (res.code === '0') {
                                departmentTrees().then((res) => {
                                    this.data2 = res.data.list;
                                });
                                this.xr(this.ind);
                                this.$message({
                                    message: '创建成功',
                                    type: 'success',
                                });
                                this.$refs[formName].resetFields();
                                this.dialogFormVisible1 = false;
                            } else {
                                this.$message.error(res.error);
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err.data.error);
                        });
                } else {
                    return false;
                }
            });
        },
        //编辑员工弹框
        handleClick(data) {
            this.dialogFormVisible1 = true;
            this.exchange = false;
            staffI({
                staffId: data.id,
            }).then((res) => {
                if (res.code == 0) {
                    this.ruleForm = {
                        staffId: data.id,
                        departmentId: '',
                        roleId: res.data.roleId,
                        name: res.data.name,
                        username: res.data.username,
                        mobileNumber: res.data.mobileNumber,
                        WxNumber: res.data.WxNumber,
                        setting: {
                            clueSwitch1:
                                res.data.setting.clueSwitch == 1 ? true : false,
                            dailyReceiveMax: res.data.setting.dailyReceiveMax,
                            clueFundNeedsMax: res.data.setting.clueFundNeedsMax,
                            clueQualityType1: [],
                            callServiceEnabled:
                                res.data.setting.callServiceEnabled,
                            wechat: res.data.setting.wechat,
                        },
                    };
                    this.radio = res.data.setting.callServiceEnabled + '';
                    console.log(
                        res.data.setting.callServiceEnabled,
                        'asdasdasdasdasdasdasdasda'
                    );
                    this.kaiTon = res.data.setting.callServiceEnabled;
                    console.log(this.kaiTon, 'kaiton');
                    console.log(res, 'biaji');
                    this.dataRandom =
                        res.data.setting.dailyReceiveMax != 0 ? 1 : 0;
                    this.moneyRandom =
                        res.data.setting.clueFundNeedsMax != 0 ? 1 : 0;
                    let arr = res.data.setting.clueQualityType;
                    let e = arr.split(',');
                    if (e.map(Number).length > 0) {
                        this.ruleForm.setting.clueQualityType1 = e.map(Number);
                    }
                    //判读是否是必填项
                    if (this.ruleForm.setting.clueSwitch1 === false) {
                        this.ert = {
                            dailyReceiveMax: '',
                            clueFundNeedsMax: '',
                        };
                    } else {
                        this.ert = {
                            dailyReceiveMax: 'setting.dailyReceiveMax',
                            clueFundNeedsMax: 'setting.clueFundNeedsMax',
                        };
                    }
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //编辑员工操作
        editForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.departmentId = this.ind.id;
                    let text = this.ruleForm.setting.clueQualityType1;
                    let c = text.join(',');
                    this.ruleForm.setting.clueQualityType = c;
                    this.ruleForm.setting.clueSwitch =
                        this.ruleForm.setting.clueSwitch1 === true ? 1 : 0;
                    console.log(this.ruleForm, 'rel');
                    console.log(this.ruleForm);
                    modify_staff(this.ruleForm)
                        .then((res) => {
                            if (res.code === '0') {
                                this.xr(this.ind);
                                this.$message({
                                    message: '编辑成功',
                                    type: 'success',
                                });
                                this.dialogFormVisible1 = false;
                            } else {
                                this.$message.error(res.error);
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err.data.error);
                        });
                } else {
                    return false;
                }
            });
        },
        // 员工客户全部丢入公海
        // throwsea() {
        //   staff_clients_public({ outStaffId: this.outStaffId }).then((res) => {
        //     if (res.code == 0) {
        //       this.$message({
        //         message: "已全部丢入公海",
        //         type: "success",
        //       });
        //       this.cfp = false;
        //     }
        //   });
        // },
        //删除员工的事件
        ygremove(row) {
            delete_staff({
                staffId: row.id,
            }).then((res) => {
                if (res.code == 0) {
                    departmentTrees().then((res) => {
                        this.data2 = res.data.list;
                    });
                    this.xr(this.ind);
                    this.$message({
                        message: '删除成功',
                        type: 'success',
                    });
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //启用禁用员工
        openstatus(data) {
            open_staff({
                staffId: data.id,
            }).then(() => {
                this.xr(this.ind);
            });
        },
        //表单重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogFormVisible1 = false;
            this.$nextTick(() => {
                this.dataRandom = 0;
                this.moneyRandom = 0;
            });
        },
        //element中的搜索操作
        querySearch(queryString, cb) {
            search({
                keyWord: this.state,
            }).then((res) => {
                if (res.code == 0) {
                    this.restaurants = res.data.list;
                    let restaurants = this.restaurants;
                    let results = queryString
                        ? restaurants.filter(this.createFilter(queryString))
                        : restaurants;
                    cb(results);
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.name
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) !== -1
                );
            };
        },
        getNode() {},
        //搜索框数据操作
        handleSelect(item) {
            //通过搜索框数据的item来获取树形状图的data和node数据
            // this.getNode(this.data2)
            // let searchNode = this.data2.filter((item) => {
            //   return item.name.indexOf()
            // })
            console.log(item);
            console.log(this.data2);
            let val = this.findTreeStaffItem(this.data2, item.departmentId);
            let object = this.$refs.tree.getNode(val);
            console.log(object);
            this.breadOption = [];
            let nodeData = this.$refs.tree.getNode(val);
            // // //面包屑导航的获取
            while (nodeData.parent != null) {
                this.breadOption.unshift({
                    title: nodeData.data.name,
                    id: nodeData.data.id,
                    data: nodeData.data,
                });
                nodeData = nodeData.parent;
            }
            // // //页面整体渲染
            this.xr(object.data);
            // // //点击后通过id来修改树状图的状态
            this.treeLook(object.data.id);
        },
        //部门员工表格的选择按钮事件
        handleSelectionChange(val) {
            this.qxanniu = [];
            for (let i = 0; i < val.length; i++) {
                this.qxanniu.push(val[i].id);
            }
        },
        //页面整体渲染的方法
        xr(content) {
            this.ind = content;
            content.id = content.departmentId
                ? content.departmentId
                : content.staffId
                ? content.staffId
                : content.id;
            if (content.id != null) {
                // this.zhankai = [content.id];
            }

            department_detail({
                departmentId: content.id,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
            }).then((res) => {
                if (res.code == 0) {
                    console.log(res.data);
                    this.ok = content.name;
                    this.zbm = res.data.subDepartmentList;
                    this.tableData = res.data.staffList;
                    this.tableDataLen = res.data.total;
                    for (let i = 0; i < this.tableData.length; i++) {
                        this.tableData[i].createTime = getYMDHMS(
                            this.tableData[i].createTime
                        );
                    }
                } else {
                    this.$message.error(res.error);
                }
            });
            //跟新部门列表数据
            departments().then((res) => {
                if (res.code == 0) {
                    this.options = res.data.list;
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //点击树形控件发生的事件
        rightClick(object) {
            console.log(object);
            this.clientStatus.pageSize = 10;
            this.clientStatus.pageNo = 1;
            this.pageNo = 1;
            this.pageSize = 10;
            this.rowInfo = object;
            //页面整体渲染
            this.xr(object);
            //面包屑导航获取
            this.breadOption = [];
            let nodeData = this.$refs.tree.getNode(object);
            while (nodeData.parent != null) {
                this.breadOption.unshift({
                    title: nodeData.data.name,
                    id: nodeData.data.id,
                    data: nodeData.data,
                });
                nodeData = nodeData.parent;
            }
        },
        //点击面包屑导航
        handleIn(id, object) {
            //面包屑导航显示的操作
            for (let i = this.breadOption.length; i > 0; i--) {
                if (this.breadOption[i - 1].id != id) {
                    this.breadOption.pop();
                } else {
                    break;
                }
            }
            //页面整体渲染
            this.xr(object);
            //点击后通过id来修改树状图的状态
            this.treeLook(id);
        },
        //点击后通过id来修改树状图的状态
        treeLook(c) {
            console.log('c', c);
            //选中状态
            this.$refs.tree.setCurrentKey(c);
            //展开状态
            // this.zhankai = [c];
        },
        //修改部门名称的事件
        xiu() {
            if (this.zi == '修改') {
                this.isShow = !this.isShow;
                this.zi = '保存';
            } else {
                let reg = /^[a-zA-Z\u4e00-\u9fa5][\w\u4e00-\u9fa5 -]{0,31}/;
                if (reg.test(this.ok)) {
                    modify_department({
                        departmentId: this.ind.id,
                        name: this.ok,
                    }).then(() => {
                        departmentTrees().then((res) => {
                            this.data2 = res.data.list;
                            for (let i = 0; i < this.breadOption.length; i++) {
                                if (this.breadOption[i].id === this.ind.id) {
                                    this.breadOption[i].title = this.ok;
                                }
                            }
                            this.title = this.data2[0].name;
                        });
                        // this.zhankai = [this.ind.id];
                        this.isShow = !this.isShow;
                        this.zi = '修改';
                        this.$message({
                            message: '修改成功',
                            type: 'success',
                        });
                    });
                } else {
                    this.$message.error('格式错误');
                }
            }
        },
        //获取删除那个部门  召唤弹框
        remove(row) {
            console.log(row);
            this.tkong = row;
            this.item = row.name ? row.name : this.ok;
            console.log('this.item', this.item);
            this.dialogVisible = true;
        },
        //删除部门的操作
        remove1() {
            delete_department({
                departmentId: this.tkong.id,
            })
                .then((res) => {
                    if (res.code == 0) {
                        departmentTrees().then((res) => {
                            this.data2 = res.data.list;
                        });
                        console.log(this.ind);
                        // this.xr(data);
                        department_detail({
                            departmentId: res.data.pid,
                            pageNo: this.pageNo,
                            pageSize: this.pageSize,
                        }).then((res) => {
                            if (res.code == 0) {
                                let i = '';
                                this.breadOption.forEach((item, index) => {
                                    if (item.id == this.tkong.id) {
                                        i = index;
                                    }
                                });

                                this.breadOption = this.breadOption.splice(
                                    0,
                                    i
                                );
                                this.ind =
                                    this.breadOption.length > 0
                                        ? this.breadOption[
                                              this.breadOption.length - 1
                                          ]
                                        : this.breadOption;
                                console.log(this.ind);
                                this.rowInfo = this.ind;
                                this.ok = res.data.name;
                                this.zbm = res.data.subDepartmentList;
                                this.tableData = res.data.staffList;
                                this.tableDataLen = res.data.total;
                                for (
                                    let i = 0;
                                    i < this.tableData.length;
                                    i++
                                ) {
                                    this.tableData[i].createTime = getYMDHMS(
                                        this.tableData[i].createTime
                                    );
                                }
                            } else {
                                this.$message.error(res.error);
                            }
                        });
                        this.dialogVisible = false;
                        this.$message.success('已删除部门');
                        // this.$notify.info({
                        //   title: "消息",
                        //   message: "已将部门删除",
                        // });
                    } else {
                        this.$message.error(res.error);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.data.error);
                });
        },
        //查找父级id
        find(index, arr) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].children !== undefined) {
                    for (let j = 0; j < arr[i].children.length; j++) {
                        if (index === arr[i].children[j].id) {
                            return arr[i];
                        } else if (arr[i].children.length > 0) {
                            this.find(index, arr[i].children);
                        }
                    }
                }
            }
        },
        remove2() {
            delete_department({
                departmentId: this.ind.id,
            })
                .then((res) => {
                    let idc = this.find(this.ind.id, this.data2);
                    if (res.code == 0) {
                        departmentTrees().then((res) => {
                            this.data2 = res.data.list;
                        });
                        this.xr(idc);
                        this.dialogVisible = false;
                        this.$notify.info({
                            title: '消息',
                            message: '已将部门删除',
                        });
                    } else {
                        this.$message.error(res.error);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.data.error);
                });
        },
        //打开添加下级部门的事件
        open() {
            this.dialogFormVisible = true;
        },
        //添加下级部门的事件
        add(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(
                        'this.ruleFormTj.setPublic',
                        this.ruleFormTj.setPublic
                    );
                    console.log('this.ind', this.ind);
                    this.ind.name = this.ind.title
                        ? this.ind.title
                        : this.ind.name;
                    add_department({
                        parentDepartmentId: this.ind.id,
                        name: this.ruleFormTj.name1,
                        setPoolSea: this.ruleFormTj.setPublic,
                    }).then((res1) => {
                        if (res1.code == 0) {
                            departmentTrees().then((res) => {
                                this.data2 = res.data.list;
                            });
                            this.xr(this.ind);
                            this.dialogFormVisible = false;
                            this.$message({
                                message: '添加成功',
                                type: 'success',
                            });
                            this.ruleFormTj.name1 = '';
                        } else {
                            this.$message.error(res1.error);
                        }
                    });
                } else {
                    // this.$message.error("请将内容填写正确");
                    return false;
                }
            });
        },
        // 重分配点击数据查询
        clickData(val) {
            console.log(val);
            this.clientStatus.qualityType = val.qualityType;
            this.clientStatus.fundNeedsScopeType = val.fundNeedsScopeType;
            this.clientStatus.followStage = val.followStage;
            this.clientStatus.getCategory = val.getCategory;
            this.clientStatus.orderBy = val.orderBy;
            this.clientStatus.qualityLevel = val.qualityLevel;
            this.clientStatus.purposeLevel = val.purposeLevel;
            this.clientStatus.orderBy = val.customerOrderBy;
            this.xrclients(this.clientStatus);
        },
        // input事件
        findData() {
            this.xrclients(this.clientStatus);
        },
        // 选择时间筛选
        changeTime() {
            // 起始时间
            const start = new Date(new Date(new Date().toLocaleDateString()));
            // 结束时间
            const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
            );
            // 获取昨天
            let zuo = start.getTime() - 3600 * 1000 * 24 * 1;
            // 获取周
            let zhou = start.getTime() - 3600 * 1000 * 24 * 6;
            if (this.timeTabs == 0) this.valuetime = []; // 全部
            if (this.timeTabs == 1) this.valuetime = [start.getTime(), end]; //今日
            if (this.timeTabs == 2) this.valuetime = [zuo, start.getTime() - 1]; // 昨日
            if (this.timeTabs == 3) this.valuetime = [zhou, end]; //最近一周
            this.clientStatus.startTime = this.valuetime[0] / 1000;
            this.clientStatus.endTime = this.valuetime[1] / 1000;
            this.clientStatus.pageSize = 10;
            this.clientStatus.pageNo = 1;
            this.page = 1;
            // this.getTableData();
            this.handleCurrentChange(1);
        },
        time(val) {
            if (val == null) {
                this.timeTabs = 0;
                this.valuetime = '';
                this.clientStatus.startTime = '';
                this.clientStatus.endTime = '';
            } else {
                this.timeTabs = '';
                this.valuetime = val;
                this.clientStatus.startTime = this.valuetime[0] / 1000;
                this.clientStatus.endTime = this.valuetime[1] / 1000;
            }

            this.handleCurrentChange(1);
        },
        regive(type) {
            this.showDialog = true;
            this.regiveType = type;
            if (type == 1) {
                this.clientsIds = [];
                this.$refs.multipleTable.clearSelection();
            }
        },
        submitPartment(val) {
            // console.log(this.clientsIds);
            // console.log(this.clientStatus.staffId);
            // console.log(val);
            let newClientIds;
            if (this.regiveType == 0) {
                staff_clients_save({
                    outStaffId: this.outStaffId,
                    inStaffId: val,
                    clientIds: this.clientsIds,
                }).then((res) => {
                    if (res.code == 0) {
                        this.$message({
                            message: '已成功分配到此销售',
                            type: 'success',
                        });
                        this.xrclients(this.clientStatus);
                        this.showDialog = false;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            } else {
                console.log('outStaffId', this.outStaffId);
                console.log('val', val);
                staff_clients_all({
                    outStaffId: this.outStaffId,
                    inStaffId: val,
                }).then((res) => {
                    if (res.code == 0) {
                        this.$message({
                            message: '已成功分配到此销售',
                            type: 'success',
                        });
                        this.xrclients(this.clientStatus);
                        this.showDialog = false;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        throwSea(type) {
            this.showThrowArr = true;
            this.outSeaType = type;
            // 部门海部门池选项
            companySea().then((res) => {
                let data = res.data.list;
                // console.log(data);
                this.departmentSea = data;
            });
            if (type == 1) {
                this.clientsIds = [];
                this.$refs.multipleTable.clearSelection();
            }
        },
        submitThrowSea() {
            if (this.outSeaType == 0) {
                staff_clients_public({
                    outStaffId: this.outStaffId,
                    departmentId: this.departSeaVal,
                    clientIds: this.clientsIds,
                }).then((res) => {
                    if (res.code == 0) {
                        this.$message({
                            message: '已成功分配公海',
                            type: 'success',
                        });
                        this.xrclients(this.clientStatus);
                        this.closeThrowSea();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            } else {
                staff_clients_public_all({
                    outStaffId: this.outStaffId,
                    departmentId: this.departSeaVal,
                }).then((res) => {
                    if (res.code == 0) {
                        this.$message({
                            message: '已成功分配公海',
                            type: 'success',
                        });
                        this.xrclients(this.clientStatus);
                        this.closeThrowSea();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        closeThrowSea() {
            this.departSeaVal = '';
            this.showThrowArr = false;
        },
        handleCommand(val) {
            if (val == 'a') {
                this.regive(1);
            } else {
                this.throwSea(1);
            }
        },
    },
};
</script>
<style lang="less" scoped>
.search {
    margin-top: 10px;

    .top-search {
        position: relative;

        /deep/ .el-input__clear {
            margin-right: 23px;
        }

        .icon-area {
            position: absolute;
            right: 5px;
            top: 8px;
            border-left: 1px solid #ccc;
            padding-left: 5px;
            cursor: pointer;
        }
    }

    .right-time {
        div:not(:last-child) {
            margin-right: 10px;
        }

        /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #4086ec;
            background-color: #ecf3fd;
            box-shadow: 0 0px 0px #ccc;
            border-color: #dcdfe6;
        }

        /deep/ .el-radio-button__inner {
            color: #aaa;
            padding: 9px 5px !important;
        }
    }
}

.flex {
    display: flex;
    align-items: center;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@import '../../css/table.less';

、 .flex {
    display: flex;
    align-items: center;
}

::v-deep .el-autocomplete {
    width: 100%;
}

.auto {
    // height: auto !important;
}

.adjustment {
    ::v-deep .el-dialog {
        // width: 25% !important;
        .el-dialog__header {
            // text-align: left !important;
        }
    }
}

::v-deep
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background: #f4f8fe;
    color: #555555;
}

.personnel {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 35px;
    padding-bottom: 35px;
    background: #f6f6f6;
    height: calc(100vh - 60px);
}

::v-deep .el-dialog__header {
    text-align: center;
}

.menu__item {
    display: block;
    line-height: 20px;
    text-align: center;
    margin-top: 10px;
}

.menu {
    height: 100px;
    width: 100px;
    position: absolute;
    border-radius: 10px;
    border: 1px solid #999999;
    background-color: #f4f4f4;
}

li:hover {
    // color: white;departmentId
}

.k {
    display: flex;
    height: 100%;
}

.list {
    height: 100%;
    flex: 1;
    padding-right: 10px;
    max-width: 300px;

    .row {
        height: 100%;
        background: white;
        overflow: scroll;
        // padding: 10px;
    }
}

.content {
    height: 100%;
    flex: 5;
    overflow: hidden;

    .add {
        ::v-deep .el-dialog__header {
            text-align: center;
        }

        ::v-deep .el-form {
            width: 350px;
            margin: 0 auto;
        }
    }

    .cfp {
        ::v-deep .el-dialog__body {
            height: calc(100% - 64px);
        }

        .k {
            display: block;
            height: 40%;
        }

        ::v-deep .el-dialog {
            // width: 75% !important;
            min-width: 1150px !important;
            // height: 75vh;
            height: 535px;
            overflow: auto;

            .top {
                height: auto;
                // height: 20%;
            }

            .el-dialog__body {
                padding-top: 0;
            }

            .bottom {
                height: calc(100% - 109px);
            }

            .bottom .k {
                padding: 0;
                height: 100%;

                .tc {
                    height: calc(100% - 95px);
                }

                .block {
                    margin-top: 10px;
                }
            }
        }
    }

    .top {
        // height: 40%;
        // height: 20%;
        padding: 0;
        margin-top: 0px;

        .row {
            background: white;
            height: 100%;
            padding: 11px 20px;
            overflow: auto;

            h1 {
                color: rgba(85, 85, 85, 1);
                margin-bottom: 15px;
                margin-top: 7px;

                i {
                    font-size: 22px;
                    font-weight: bold;
                    font-style: normal;
                    display: inline-block;
                    vertical-align: -3px;
                }

                ::v-deep .el-input {
                    width: 150px;
                }
            }

            p {
                span {
                    display: inline-block;
                    margin-right: 20px;
                }
            }

            ::v-deep .el-dialog__body {
                padding-bottom: 0;
            }
        }

        .row > .div {
            width: 100%;
            background: rgba(255, 255, 255, 1);
            border-radius: 10px;
            padding: 10px;
            margin-top: 20px;
            border: 1px solid rgba(220, 223, 230, 1);

            .add {
                background-color: #fff;
                border: 1px dashed #aaa;
                color: #aaa;

                &:hover {
                    color: #4086ec;
                    border: 1px dashed #4086ec;
                    cursor: pointer;
                }
            }
        }

        .row > .div > span {
            display: inline-block;
            color: #4086ec;
            border: 1px solid #4086ec;
            padding: 6px 15px;
            // background: rgba(252, 177, 43, 1);
            background-color: #f5f9fe;
            border-radius: 4px;
            font-size: 14px;
            position: relative;
            margin: 10px;
        }

        .row > .div > span > i {
            // color: white;
            position: absolute;
            top: 9px;
            right: 1px;
            // transform: rotate(45deg);
            cursor: pointer;

            &:hover {
                background: rgba(64, 134, 236, 1);
                color: #fff;
                border-radius: 10px;
            }

            &:active {
                color: #4086ec;
                background-color: #fff;
            }
        }
    }

    .bottom {
        // overflow: auto;
        height: 100%;
        background: white;

        .none {
            height: calc(100% - 28px);
            display: flex;
            justify-content: center;
            align-items: center;

            .item {
                text-align: center;

                p {
                    color: #c2d4eb;
                    font-size: 7px;
                    line-height: 18px;
                }
            }
        }

        ::v-deep.el-table::before {
            height: 0;
        }

        ::v-deep .el-table th {
            padding: 10px 0;
        }

        ::v-deep .el-table td {
            padding: 8px 0;
        }

        ::v-deep .el-table thead tr th {
            background: rgba(245, 247, 249, 1);
            color: #333;
            font-weight: 900;
        }

        ::v-deep .el-input-group__append {
            background: rgba(225, 225, 225, 1);
            color: #222;
            padding: 0;
            width: 46px;
            text-align: center;
        }

        .row {
            background: white;
            height: 100%;
            padding: 20px 20px;

            .p {
                margin-bottom: 15px;

                span {
                    display: inline-block;
                    position: relative;
                    padding-left: 17px;
                }

                span::before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    background: url(../../../public/images/yuangong.png);
                }
            }

            .jb {
                ::v-deep .el-form-item__label {
                    width: 65px !important;
                }

                ::v-deep .el-form-item__content {
                    margin-left: 65px !important;
                }
            }

            ::v-deep .el-dialog__body {
                // margin-left: auto !important;
            }

            ::v-deep .el-dialog__header {
                text-align: center;
            }

            ::v-deep .el-dialog {
                width: 50%;
            }

            .kg {
                ::v-deep .el-form-item__label {
                    line-height: 1;
                }

                ::v-deep .el-form-item {
                    margin-bottom: 0;
                }

                ::v-deep .el-form-item__content {
                    line-height: 1;
                }
            }

            .button {
                text-align: center;
                margin-top: 15px;

                ::v-deep .el-form-item__content {
                    margin-left: 0 !important;
                }
            }

            .xuanze {
                background: #e3784b !important;
                color: white !important;
            }

            .grid-content {
                border: 1px solid #cacaca;
                border-radius: 10px;
                padding: 15px;
                height: 280px;
            }

            .tk {
                ::v-deep .el-dialog__body {
                    padding: 10px 20px;
                }

                p {
                    margin-bottom: 10px;
                }

                span {
                    width: 70px;
                    display: inline-block;
                    text-align: right;
                }

                h4 {
                    margin-bottom: 20px;
                    margin-top: 20px;
                    line-height: 24px;
                    font-size: 18px;
                    color: #303133;
                    text-align: center;
                    font-weight: 400;
                }
            }

            i {
                font-style: normal;
            }
        }
    }
}

.adddepart-area {
    font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #555555;
}

.depart-item {
    font-family: 'Arial Normal', 'Arial';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #4086ec;
    border: 1px solid rgba(160, 195, 246, 1);
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    position: relative;

    i {
        position: absolute;
        right: 10px;
        top: 13px;

        &:hover {
            background: rgba(64, 134, 236, 1);
            color: #fff;
            border-radius: 10px;
        }

        &:active {
            color: #4086ec;
            background-color: #fff;
        }
    }
}

.add-depart-item {
    color: #aaaaaa;
    border: 1px dashed #aaa;
    background-color: #fff;
    cursor: pointer;

    &:hover {
        border: 1px dashed rgba(160, 195, 246, 1);
        color: rgba(160, 195, 246, 1);
    }

    i {
        &:hover {
            background-color: #fff;
            color: #4086ec;
        }
    }
}

.bottom {
    margin-bottom: 0px !important;
}

.base-info {
    border-bottom: 1px solid #ccc;
    align-items: center;
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.reset-password {
    color: #67c23a;
    border: 1px solid rgba(179, 225, 157, 1);
    background-color: rgba(240, 249, 235, 1);

    &:hover {
        background-color: rgba(148, 212, 117, 1);
        color: #fff;
    }

    &:active {
        background-color: rgba(72, 136, 41, 1);
    }
}

.receive {
    /deep/ .el-form-item__error {
        left: 156px;
    }
}

.xin {
    font-size: 30px;
    vertical-align: middle;
    color: #999;
    cursor: pointer;
}

.ping {
    color: #ff8190;
}

.call-choose {
    // width: 100%;
    // border: 1px solid black;
    margin: 20px 0 25px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
</style>
